@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  block-sizing: border-box;
}

.bottommargin {
  margin-top: 20px;
}

.landing {
  margin-left: 20%;
  margin-right: 20%;
}

.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
}


.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(1.4rem);
  color: black;
}

.App-link {
  color: #61dafb;
}

/* Color Theme Swatches in Hex */
.Mote-1-hex { color: #63A5BF; }
.Mote-2-hex { color: #9CCED9; }
.Mote-3-hex { color: #BF9B7A; }
.Mote-4-hex { color: #8C6751; }
.Mote-5-hex { color: #593734; }

/* Color Theme Swatches in RGBA */
.blue1 { color: rgba(99, 165, 191, 1); }
.blue2 { color: rgba(156, 205, 216, 1); }
.brown3 { color: rgba(191, 154, 122, 1); }
.brown2 { color: rgba(140, 102, 81, 1); }
.brown1 { color: rgba(89, 54, 51, 1); }

/* Color Theme Swatches in HSLA */
.Mote-1-hsla { color: hsla(196, 41, 57, 1); }
.Mote-2-hsla { color: hsla(191, 44, 73, 1); }
.Mote-3-hsla { color: hsla(28, 35, 61, 1); }
.Mote-4-hsla { color: hsla(22, 26, 43, 1); }
.Mote-5-hsla { color: hsla(5, 26, 27, 1); }


.logocorners {
  border-radius: 25px 3px 25px 3px !important;
}

.inline-block {
  display: inline-block;
}

.iconButton {
  color: #555555;

  &:hover {
    color: #8d8d8d;
  }

  &:active {
    color: #444444;
  }
}