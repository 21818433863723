.sign-up {
    display: flex;
    flex-direction: column;
    //max-width: 380px;
    margin:20px 6rem;
}

.title {
    margin: 10px 0;
}

@media screen and (max-width: 500px){
    .sign-up{
        margin:20px 0;
    }
}
