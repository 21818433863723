.listImage {
    max-width: 30%;
    height: auto;
    margin-bottom:auto;
    vertical-align: top;
    /* Consider re-adding if we start having a different color of the images and the background.
    border-bottom-right-radius: 30px;
    border-top-left-radius: 30px;
    */
}

.addedEquipmentListElement {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.addedEquipmentListItemTitle {
    font-size: medium;
    color: rgb(102, 102, 102);
    margin-bottom: 0px;
}

.addedEquipmentListItemCategory {
    padding-top: 3px;
    color:rgb(175, 175, 175);
}

.listItemContent {
    flex-grow: 1;
    text-align: left;
    padding-left: 10px;
}

.addedEquipmentListElement:not(:last-child) {
    margin-bottom: 10px;
}

.numberInput .ui.input > input {
    font-weight: bold;
    width: 30px;
}

.listItemBottomRow {
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding-right: 10px;;
}

.iconButton {
    margin-left: 5px!important;
}
