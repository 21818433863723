main { 
    padding: 1em; 
}

.someImage {
    background-color: gray;
    margin-right: 30px;
    height: 50%;
}

h1 {
    text-align: center;
}

.addedEquipmentListTitle {
    font-size: 20px;
    color: rgb(92, 92, 92);
    padding-top: 4px;
}

.resizedSearchBoxContainer {
    text-align: right!important;
    margin-right: 0px;
    margin-left:auto;
}

.topBar {
    text-align: left!important;
}

.autoCompleteList {
    padding-inline-start: 0px;
    text-align: right;
    margin-top: 0px;
}

.mainInput {
    margin-left: 9px;
}

.searchResultsContainer {
    padding-top: 0px!important;
}

.addedEquipmentListTitle {
    text-align: left;
}

.saveButton {
    border-bottom-right-radius: 25px!important; 
    border-top-left-radius: 25px!important; 
}