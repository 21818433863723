.card {
    display: inline-block!important;
    margin: 10px!important;
    text-align: left;
}

.cardContent {
    height: 136px;
}

.cardImage {
    margin: 30px!important;
}

.ais-Highlight-nonHighlighted {
    font-size: large!important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
}

.ais-Highlight-highlighted {
    font-size: large!important;
    background-color: #fff;
    font-weight: bold!important;
    font-style: unset;
    font-family: Arial, Helvetica, sans-serif;
}

.inline {
    display: flex;
    justify-content: space-between;
}

.roundButton {
    border-radius:20px!important;
    height: fit-content;
    margin-top: 4px!important;
    width:38px;
}

.custom-button {
    width:100%!important;
    margin-right: 0px!important;
}

.category-text {
    color: gray;
    padding-top: 3px;
}