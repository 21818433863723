p{
    letter-spacing: 1.4px;
}

.columnmargin {
    margin-left:10px;
    margin-right:10px;
}

.register2 {
    margin-top: 40px !important;
    // margin-bottom: 10px !important;
    width: 100% !important;
}

.segmentmargin {
    margin-top:30px!important;
    margin-bottom:30px !important;
    
}

.main-wrapper {
    margin: 0px 8% 0px 8%;
    display:flex;
    flex-direction: column;
    text-align: left;
    

    @media screen and (max-width: 733px){
        justify-content: center;
        align-items: center;
        margin: auto;
        text-align: center;

    
    }

}

.filmting-logo-img {
    text-align: center;
}


.space-between {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-evenly !important;
}

.image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25px 3px 25px 3px;
    margin:auto;
    
    // &.headerimage {
        
    //     background-image: url("https://firebasestorage.googleapis.com/v0/b/filmting-dev.appspot.com/o/img%2FMementoActivateU%20(5%20of%2027).jpg?alt=media&token=55535c05-a75a-4b88-82af-099dbb0390f3");
        
        
    // }
    


    &.first {
        min-height: 400px;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/filmting-dev.appspot.com/o/img%2Ftheregisti-kmeLU0mkJf8-unsplash.jpg?alt=media&token=43d40b96-fa41-4503-8985-22aaf868ca27");
        transform: scaleX(-1);
    }
    
    &.second {
    margin: 20px;
    
    
    background-image: url("https://firebasestorage.googleapis.com/v0/b/filmting-dev.appspot.com/o/img%2FWardruna-Lyfjaberg-BTS-35.jpg?alt=media&token=4a3cf8b4-d7c4-49ac-8b00-c95cf84f1d5f");
    }

    &.third {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/filmting-dev.appspot.com/o/img%2FMementoActivateU%20(5%20of%2027).jpg?alt=media&token=55535c05-a75a-4b88-82af-099dbb0390f3");
        min-height: 610px;
    }
}


.utvikles {
    text-align: center;
    margin-top:80px !important;
    margin-bottom:80px !important;
    
}



.section-1 {
    flex-direction: row;
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    line-height: 2rem;
}



.section-1B {
    display: float;

    justify-content: left;
    align-items: left;
    margin: auto;
    //margin-right: 10%;
    //height: 100%;
    text-align: left;
    align-content: flex-start;
    //max-width: 50rem;
    
    
    
    
    
}

.welcomebutton {
    //display: flex !important;
    //flex-direction:column !important ;
    margin-top: 20px;
    margin-bottom:3rem !important;
    align-content: center !important;
    justify-content: center !important;
    
}




@media screen and (max-width: 733px){
    .section-1B{
        justify-content: center;
        align-items: center;
        margin: auto;
        text-align: center;
        
    }

    .introtext{
        justify-content: center;
        align-items: center;
        margin: auto;
        text-align: center;

    }

    .second {
            visibility: hidden;
        }

    .mobilehidden {
        visibility: hidden;
        min-height:0px !important;
        max-height: 0px !important;
    }
    
}
