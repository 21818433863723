$primary-color: #3073df;
$secondary-color: black;
$background-color: white;



.default-filmting-button{
  min-width: 165px;
  border-radius: 25px 3px 25px 3px;
  width: auto;
  height: 35px;
  letter-spacing: 0.5px;
  line-height: 15px;
  padding: 0 20px 0 20px;
  font-size: 15px;
  background-color: $background-color;
  color: $primary-color;
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  border: 1px solid $primary-color;
  animation:fadeIn ease 3s;
  margin: 5px;

  &:hover {
    background-color:$background-color;
    color: $secondary-color;
    border: 1px solid $secondary-color;
    box-shadow: 2px 2px $secondary-color;
  }


  &:focus {
    outline: none;
  }

  &.google-sign-in {
    background-color: #4285f4;
    color: white;
    //height: 45px;
    //line-height: 15px;

    &:hover {
      box-shadow: 2px 2px black;
    }
  }
}
