.topheader {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;


  .logo-container {
    height: 100%;
    width: 70px;
    padding: 5px;
    
  }


  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    

    .option {
      padding: 10px 40px 15px 0;
      color: black;
      justify-content: flex-start;
    }
  }
}

.menu-button {
  border-radius: 15px 50px 15px 50px;
}