.sign-in-and-sign-up {
    max-width: 850px;
    margin:auto;
}

.introtext {
    width: 100%;
}

.extra-margin {
    margin:20px;
}

@media screen and (max-width: 733px){
.introtext {
    margin: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
}


}
